import React from 'react';
import './App.css';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import About from '../Components/About';
import Resume from '../Components/Resume';
import Portfolio from '../Components/Portfolio';
import resumeData from '../assets/resumeData.json';

export const App = () => {
    return (
        <div className="App">
            <Header data={resumeData.main}/>
            <Resume data={resumeData.resume}/>
            <section id="portfolio">
                {resumeData.portfolio ?
                    <div className="row">
                        <Portfolio title={"Writing Samples"} data={resumeData.portfolio.writing}/>{/*
                        <Portfolio title={"Project Management"} data={resumeData.portfolio.projectmgmt}/>
                        <Portfolio title={"UX Design"} data={resumeData.portfolio.designs}/> */}
                    </div>
                : null }
            </section>
            <About data={resumeData.main}/>

            {/*<Testimonials data={resumeData.testimonials}/>*/}
            {/*<Contact data={resumeData.main}/>*/}
            <Footer data={resumeData.main}/>
        </div>
    );
}

export default App;