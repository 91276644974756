import React from 'react';

const Portfolio = props => {

    const columnClassName = (props.data.length < 7 ||
        props.data.length === 9)
            ? "bgrid-thirds"
            : "bgrid-quarters";

    const projects = props.data.map(function (projects) {
        const projectImage = 'images/portfolio/' + projects.image;
        return <div key={projects.title} className="columns portfolio-item">
            <div className="item-wrap">
                <a href={projects.url} title={projects.title}>
                    <img alt={projects.title} src={projectImage}/>
                    <div className="overlay">
                        <div className="portfolio-item-meta">
                            <h5>{projects.title}</h5>
                            <p>{projects.category}</p>
                        </div>
                    </div>
                    <div className="link-icon"><i className="fa fa-link"></i></div>
                </a>
            </div>
        </div>
    })

    return (
        <div className="portfolio row">
            <div className="twelve columns collapsed">
                <h1>{props.title}</h1>
                <div id="portfolio-wrapper" className={`${columnClassName} s-bgrid-thirds cf`}>
                    {projects}
                </div>
            </div>
        </div>
    );
};

export default Portfolio;
