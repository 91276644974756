import React from 'react';
import {NetworkIcon} from "./NetworkIcon";

const Footer = (
    {
        data,
    },
) => {

    const networks = data.social.map(network =>
        <NetworkIcon key={network.name} name={network.name} url={network.url} className={network.className}/>
    )

    return (
        <footer>

            <div className="row">
                <div className="twelve columns">
                    <ul className="social-links">
                        {networks}
                    </ul>
                </div>
                <div id="go-top"><a className="smoothscroll" title="Back to Top" href="#home"><i
                    className="icon-up-open"></i></a></div>
            </div>
            <div className='row'>
                <div className="banner-text">
                    <h6>Jacqueline Moore 2024</h6>
                </div>   
            </div>
        </footer>
    );
};

export default Footer;
