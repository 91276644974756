import React from 'react';

export const NetworkIcon = (
    {
        name,
        url,
        className,
    },
) => {
    return (
        <li key={name}>
            <a href={url} target="_blank" rel="noopener noreferrer"><i className={className}></i></a>
        </li>
    );
};