import React from 'react';

const About = (
    {
        data,
    },
) => {

    //const name = this.props.data.name;
    const profilepic = "images/" + data.image;
    const bio = data.bio;
    const listIntro = data.listIntro;
    const bioList = data.bioList;
    //const email = this.props.data.email;

    return (
        <section id="about">
            <div className="row">
                <div className="four columns">
                    <img className="profile-pic" src={profilepic} alt="Jacqueline Moore Profile Pic"/>
                </div>
                <div className="eight columns main-col">
                    <h2>About Me</h2>

                    <p>{bio}</p>
                    <p>{listIntro}</p>
                    <ul>
                            {bioList
                                ? bioList.map(item => <li key={item}>{item}</li>)
                                : null}
                    </ul>
                    <div className="row">
                        <div className="columns download">
                            <p>
                                <a href="Jacqueline Moore Resume.pdf" className="button" download>
                                    <i className="fa fa-download"/>Download Resume
                                </a>
                                <a href="mailto:jacmoore@ucdavis.edu" className="button" download>
                                    <i className="fa fa-envelope"/>Contact Me
                                </a>
                            </p>
                        </div>
                        <div className="columns download">
                            <p>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    );
};

export default About;
