import React from 'react';
import {NetworkIcon} from "./NetworkIcon";

const Header = (
    {
        data,
    },
) => {

    //const name = this.props.data.name;
    //const occupation = this.props.data.occupation;
    // const occupation2 = this.props.data.occupation2;
    //const description = this.props.data.description;
    //const city = this.props.data.address.city;
    const networks = data.social.map(network =>
        <NetworkIcon key={network.name} name={network.name} url={network.url} className={network.className}/>
    )

    return (
        <header id="home">

            <div className="row banner">
                <div className="banner-text">
                    <h1 className="responsive-headline">Hi, I'm Jacqueline.</h1>
                    <h3>I'm a technical writer based in San Francisco. I write developer docs at Salesforce about web components, APIs, and server-side rendering.</h3>
                    {/* Horizontal line that I don't want above socials! */}
                    {/* <hr/> */}
                    <ul className="social">
                        {networks}
                    </ul>
                </div>
            </div>

            <p className="scrolldown">
                <a className="smoothscroll" href="#resume"><i className="icon-down-circle"></i></a>
            </p>

        </header>
    );
};

export default Header;
