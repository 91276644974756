import React from 'react';

const Resume = (
    {
        data,
    },
) => {

    // const skillmessage = this.props.data.skillmessage;

    const work = data.work.map((work) => {
        const key = `${work.company} - ${work.years}`;
        return <div key={key}><h3>{work.company}</h3>
            <p className="info">{work.title}<span>&bull;</span> <em className="date">{work.years}</em></p>
            <p className="description">{work.description}</p>
        </div>
    });

    const activities = data.activities.map(function (activity) {
        const key = `${activity.company} - ${activity.years}`;
        return <div key={key}><h3>{activity.company}</h3>
            <p className="info">{activity.title}<span>&bull;</span> <em className="date">{activity.years}</em></p>
            <p className="description">{activity.description}</p>
        </div>
    });

    const education = data.education.map(education =>
        <div key={education.school}>
            <h3>{education.school}</h3>
            {education.degree.map(degree =>
                <p key={`${education.school}--${degree}`} className="info">{degree} <span>&bull;</span><em className="date">{education.graduated}</em>
                </p>
            )}
            {/*<p className="info">{education.degree} <span>&bull;</span><em className="date">{education.graduated}</em></p>*/}
            <p className="description">{education.description}</p>
        </div>);



    // const skills = this.props.data.skills.map(function (skills) {
    //     const className = 'bar-expand ' + skills.name.toLowerCase();
    //     return <li key={skills.name}><span style={{width: skills.level}}
    //                                        className={className}></span><em>{skills.name}</em></li>
    // })

    return (
        <section id="resume">

            
            <div className="row work">

                <div className="three columns header-col">
                    <h1><span>Experience</span></h1>
                </div>

                <div className="nine columns main-col">
                    {work}
                </div>
            </div>

             <div className="row activities">

                <div className="three columns header-col">
                    <h1><span>Community</span></h1>
                </div>

                <div className="nine columns main-col">
                    {activities}
                </div>
            </div>

            <div className="row education">
                <div className="three columns header-col">
                    <h1><span>Education</span></h1>
                </div>

                <div className="nine columns main-col">
                    <div className="row item">
                        <div className="twelve columns">
                            {education}
                        </div>
                    </div>
                </div>
            </div>


            {/*<div className="row skill">*/}

                {/*<div className="three columns header-col">*/}
                    {/*<h1><span>Skills</span></h1>*/}
                {/*</div>*/}

                {/*<div className="nine columns main-col">*/}

                    {/*<p>{skillmessage}*/}
                    {/*</p>*/}

                    {/*<div className="bars">*/}
                        {/*<ul className="skills">*/}
                            {/*{skills}*/}
                        {/*</ul>*/}
                    {/*</div>*/}
                {/*</div>*/}
            {/*</div>*/}
        </section>
    );
};

export default Resume;
